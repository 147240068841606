// Import just what we need

// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import Collapse from 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

function ready(callback) {
  if (document.readyState != "loading") {
    callback();
  } else {
    document.addEventListener("DOMContentLoaded", callback);
  }
}

function oneEventListener(el, eventType, callback) {
    function wrappedCallback(event) {
        callback = callback.bind(this);
        callback(event);
        el.removeEventListener(eventType, wrappedCallback);
    }
    el.addEventListener(eventType, wrappedCallback);
}

function navigateToAccordion() {
    var collapseTargetSel;
    var targetSel = collapseTargetSel = window.location.hash.substr(1);
    if (!targetSel) {
        return;
    }
    targetSel = targetSel.replace("collapse-", "");
    if (targetSel.includes("=")) return;
    if (!collapseTargetSel.includes("collapse-")) {
        collapseTargetSel = "collapse-" + collapseTargetSel;
    }
    var selector = "#" + collapseTargetSel + ".collapse";
    var el = document.querySelector(selector);
    if (el == null) {
        // Support old accordion behavior.
        el = document.querySelector(".accordion-legacy #" + targetSel);
        if (el == null) return;
        // If it's not a header, return.
        if (["h1","h2","h3","h4","h5","h6"].indexOf("target.nodeName") <= -1) {
            return
        }
        var clickEvent = new MouseEvent("click", {bubbles: true});
        el.dispatchEvent(clickEvent);
        el.scrollIntoView({behavior: "smooth"});
        return;
    }

    Collapse.getOrCreateInstance(el, {toggle: false}).show();
    oneEventListener(el, "shown.bs.collapse", function(event) {
        var accordionHeader = this.previousElementSibling;
        accordionHeader.querySelector(".accordion-button")?.focus({preventScroll: true});
        accordionHeader.scrollIntoView({behavior: "smooth"});
        history.replaceState(null, '', '#' + targetSel);
    })
}

// Import the old style accordion behavior.
import './accordion.js';

ready(function() {
    var navCollapse = document.querySelector("#navSearch.collapse");
    navCollapse?.addEventListener("shown.bs.collapse", function() {
        this.children[0].focus();
    });

    // Swap out show/hide text for collapse toggles that have a
    // "data-hide-text" attribute set.
    document.querySelectorAll(
        "[data-hide-text][data-bs-toggle='collapse'], \
         [data-show-text][data-bs-toggle='collapse']"
    ).forEach(el => {
        var targetSel = el.getAttribute("data-bs-target") ?? el.getAttribute("href");
        var collapseEl = document.querySelector(targetSel);
        collapseEl.addEventListener("hide.bs.collapse", function () {
            el.setAttribute("data-hide-text", el.innerText);
            el.innerText = el.getAttribute("data-show-text");
        });
        collapseEl.addEventListener("show.bs.collapse", function () {
            el.setAttribute("data-show-text", el.innerText);
            el.innerText = el.getAttribute("data-hide-text");
        });
    });

    document.querySelectorAll(".collapse").forEach(el => {
        el.addEventListener("show.bs.collapse", function() {
            this.removeAttribute("hidden");

            // Show parents in case they're collapsed (for navigating
            // to nested accordions with URL #hash)
            var parentCollapse = this.parentElement.closest(".collapse");
            if (parentCollapse != null) {
                Collapse.getOrCreateInstance(parentCollapse, {toggle: false}).show();
            }
        });

        el.addEventListener("hidden.bs.collapse", function(event) {
            event.stopPropagation();
            this.setAttribute("hidden", "until-found");
        });

        el.addEventListener("beforematch", function() {
            this.classList.add("no-transition");
            Collapse.getOrCreateInstance(this, {toggle: false}).show();
            oneEventListener(this, "shown.bs.collapse", function () {
                this.classList.remove("no-transition");
            });
        });
    });
    navigateToAccordion();
    window.addEventListener("hashchange", navigateToAccordion);
});
