import Collapse from 'bootstrap/js/src/collapse';

var headers = ["H1","H2","H3","H4","H5","H6"];

function ready(callback) {
  if (document.readyState != "loading") {
    callback();
  } else {
    document.addEventListener("DOMContentLoaded", callback);
  }
}

ready(function() {
  var accordion = document.querySelector(".accordion-legacy");
  if (accordion == null) {
    return
  }
  accordion.addEventListener("click", function(e) {
    var target = e.target,
        name = target.nodeName.toUpperCase();
    if(headers.indexOf(name) > -1) {
      var subItem = target.nextElementSibling;
      if (subItem.classList.contains("opened")) {
        subItem.classList.remove("opened");
        subItem.classList.add("collapse", "show");
      }
      new Collapse(subItem, {toggle: true});
    }
  });

  accordion.querySelectorAll("a").forEach((el) => {
    el.addEventListener("click", function() {
      href = el.getAttribute('href');
      current = window.location.href.split('#')[0];
      console.log(href);
      history.pushState({needPop: true}, href, current + '#' + href);
    });
  });

});
